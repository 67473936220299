import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

/* GET METHOD */

export const generateCity = (long, lat) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      // const url = `${geoServerBaseUrl}/sini/generate_city_object?long=${long}&lat=${lat}`;
      const url = `https://karlo.mapid.io/geocode?latitude=${lat}&longitude=${long}`;
      const response = await axios.get(url, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const editLocation = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/blog/edit_location`;
      const response = await axios.post(url, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
